define("discourse/plugins/discourse-events/discourse/components/events-message", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/component", "I18n"], function (_exports, _decorators, _computed, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const icons = {
    error: "times-circle",
    success: "check-circle",
    warn: "exclamation-circle",
    info: "info-circle"
  };
  const urls = {
    provider: "https://discourse.pluginmanager.org/t/539",
    source: "https://discourse.pluginmanager.org/t/540",
    connection: "https://discourse.pluginmanager.org/t/541",
    event: "https://discourse.pluginmanager.org/t/543",
    log: "https://discourse.pluginmanager.org/t/543"
  };
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("message.type"), _dec2 = (0, _decorators.default)("message.key", "view", "message.opts"), _dec3 = (0, _decorators.default)("view"), (_obj = {
    classNameBindings: [":events-message", "message.type", "loading"],
    showDocumentation: (0, _computed.not)("loading"),
    showIcon: (0, _computed.not)("loading"),
    hasItems: (0, _computed.notEmpty)("items"),
    icon(type) {
      return icons[type] || "info-circle";
    },
    text(key, view, opts) {
      return _I18n.default.t(`admin.events.message.${view}.${key}`, opts || {});
    },
    documentation() {
      return _I18n.default.t(`admin.events.message.documentation`);
    },
    documentationUrl(view) {
      return urls[view] || "https://discourse.pluginmanager.org/c/discourse-events";
    }
  }, (_applyDecoratedDescriptor(_obj, "icon", [_dec], Object.getOwnPropertyDescriptor(_obj, "icon"), _obj), _applyDecoratedDescriptor(_obj, "text", [_dec2], Object.getOwnPropertyDescriptor(_obj, "text"), _obj), _applyDecoratedDescriptor(_obj, "documentation", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "documentation"), _obj), _applyDecoratedDescriptor(_obj, "documentationUrl", [_dec3], Object.getOwnPropertyDescriptor(_obj, "documentationUrl"), _obj)), _obj)));
});