define("discourse/plugins/discourse-events/discourse/components/events-calendar-event", ["exports", "discourse/lib/url", "@ember/component", "@ember/object"], function (_exports, _url, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_obj = {
    tagName: "li",
    selectEvent(url) {
      event?.preventDefault();
      const responsive = this.get("responsive");
      if (responsive) {
        _url.default.routeTo(url);
      } else {
        this.toggleProperty("showEventCard");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "selectEvent", [_object.action], Object.getOwnPropertyDescriptor(_obj, "selectEvent"), _obj)), _obj));
});