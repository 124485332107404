define("discourse/plugins/discourse-events/discourse/components/events-event-row", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("showSelect"), _dec2 = (0, _decorators.observes)("selectAll"), (_obj = {
    tagName: "tr",
    classNameBindings: [":events-event-row", "showSelect", "selected"],
    selected: false,
    toggleWhenShowSelect() {
      if (!this.showSelect) {
        this.set("selected", false);
      }
    },
    toggleWhenSelectAll() {
      this.set("selected", this.selectAll);
    },
    click() {
      if (this.showSelect) {
        this.selectEvent();
      }
    },
    selectEvent() {
      this.toggleProperty("selected");
      this.modifySelection([this.event], this.selected);
    },
    actions: {
      selectEvent() {
        this.selectEvent();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleWhenShowSelect", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleWhenShowSelect"), _obj), _applyDecoratedDescriptor(_obj, "toggleWhenSelectAll", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleWhenSelectAll"), _obj)), _obj)));
});