define("discourse/plugins/discourse-events/discourse/routes/build-calendar-route", ["exports", "discourse/routes/build-category-route", "discourse/routes/build-topic-route", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _buildCategoryRoute, _buildTopicRoute, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildCalendarRoute;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function buildCalendarRoute(routeConfig) {
    const klass = routeConfig.type === 'category' ? (0, _buildCategoryRoute.default)({
      filter: 'calendar'
    }) : (0, _buildTopicRoute.default)('calendar');
    return class extends klass {
      constructor() {
        super(...arguments);
        _defineProperty(this, "templateName", "discovery/calendar");
      }
      beforeModel(transition) {
        super.beforeModel(...arguments);
        const routeName = this.routeName;
        const queryParams = this.paramsFor(routeName);
        if (!queryParams.start || !queryParams.end) {
          const month = moment().month();
          const year = moment().year();
          const {
            start,
            end
          } = (0, _dateUtilities.calendarRange)(month, year);
          this.setProperties({
            start,
            end
          });
        }
      }
      setupController(controller, model) {
        super.setupController(...arguments);
        const start = this.get("start");
        const end = this.get("end");
        if (start || end) {
          let initialDateRange = {};
          if (start) {
            initialDateRange["start"] = start;
          }
          if (end) {
            initialDateRange["end"] = end;
          }
          const controllerName = this.routeConfig.type === "category" ? "discovery.calendarCategory" : "discovery.calendar";
          this.controllerFor(controllerName).setProperties({
            initialDateRange
          });
        }
      }
    };
  }
});