define("discourse/plugins/discourse-events/discourse/components/add-to-calendar", ["exports", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "discourse-common/utils/decorators", "@ember/runloop", "@ember/component"], function (_exports, _dateUtilities, _decorators, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("topic.event"), (_obj = {
    expanded: false,
    classNames: "add-to-calendar",
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    outsideClick(e) {
      if (!this.isDestroying && !$(e.target).closest(".add-to-calendar").length) {
        this.set("expanded", false);
      }
    },
    calendarUris() {
      const topic = this.get("topic");
      let params = {
        event: topic.event,
        title: topic.title,
        url: window.location.hostname + topic.get("url")
      };
      if (topic.location && topic.location.geo_location) {
        params["location"] = topic.location.geo_location.address;
      }
      return [{
        uri: (0, _dateUtilities.googleUri)(params),
        label: "google"
      }, {
        uri: (0, _dateUtilities.icsUri)(params),
        label: "ics"
      }];
    },
    actions: {
      expand() {
        this.toggleProperty("expanded");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "calendarUris", [_dec], Object.getOwnPropertyDescriptor(_obj, "calendarUris"), _obj)), _obj)));
});