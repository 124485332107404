define("discourse/plugins/discourse-events/discourse/components/add-event-controls", ["exports", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/components/modal/add-event", "@ember/component", "@ember/service"], function (_exports, _dateUtilities, _decorators, _addEvent, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("noText"), _dec2 = (0, _decorators.default)("event"), _dec3 = (0, _decorators.default)("category", "noText"), (_obj = {
    classNames: ["event-label"],
    modal: (0, _service.service)(),
    didInsertElement() {
      $(".title-and-category").toggleClass("event-add-no-text", this.get("iconOnly"));
    },
    valueClasses(noText) {
      let classes = "add-event";
      if (noText) {
        classes += " btn-primary";
      }
      return classes;
    },
    valueLabel(event) {
      return (0, _dateUtilities.eventLabel)(event, {
        noText: this.get("noText"),
        useEventTimezone: true,
        showRsvp: true,
        siteSettings: this.siteSettings
      });
    },
    iconOnly(category, noText) {
      return noText || this.siteSettings.events_event_label_no_text || Boolean(category && category.get("custom_fields.events_event_label_no_text"));
    },
    actions: {
      showAddEvent() {
        this.modal.show(_addEvent.default, {
          model: {
            bufferedEvent: this.event,
            event: this.event,
            update: event => {
              this.set("event", event);
            }
          }
        });
      },
      removeEvent() {
        this.set("event", null);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "valueClasses", [_dec], Object.getOwnPropertyDescriptor(_obj, "valueClasses"), _obj), _applyDecoratedDescriptor(_obj, "valueLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "valueLabel"), _obj), _applyDecoratedDescriptor(_obj, "iconOnly", [_dec3], Object.getOwnPropertyDescriptor(_obj, "iconOnly"), _obj)), _obj)));
});