define("discourse/plugins/discourse-events/discourse/controllers/events-connection-filters", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/models/connection-filter", "@ember/array"], function (_exports, _controller, _modalFunctionality, _decorators, _connectionFilter, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const QUERY_COLUMNS = [{
    name: "Event Name",
    id: "name"
  }];
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_obj = {
    queryColumns() {
      return QUERY_COLUMNS;
    },
    onShow() {
      if (!this.model.connection.filters) {
        this.model.connection.set("filters", (0, _array.A)());
      }
    },
    actions: {
      addFilter() {
        const filter = _connectionFilter.default.create({
          id: "new"
        });
        this.model.connection.get("filters").pushObject(filter);
      },
      removeFilter(filter) {
        this.model.connection.get("filters").removeObject(filter);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "queryColumns", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "queryColumns"), _obj)), _obj));
});