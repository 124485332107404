define("discourse/plugins/discourse-events/discourse/components/events-calendar-subscription", ["exports", "select-kit/components/dropdown-select-box", "discourse-common/utils/decorators", "discourse/models/category", "I18n", "discourse-common/lib/get-url"], function (_exports, _dropdownSelectBox, _decorators, _category, _I18n, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _dropdownSelectBox.default.extend((_dec = (0, _decorators.default)(), (_obj = {
    classNames: ["events-calendar-subscription"],
    modifyComponentForRow() {
      return "events-calendar-subscription-row";
    },
    getDomain() {
      return location.hostname + (location.port ? ":" + location.port : "");
    },
    content() {
      const path = this.category ? `/c/${_category.default.slugFor(this.category)}/l` : "";
      const url = this.getDomain() + (0, _getUrl.default)(path);
      const timeZone = moment.tz.guess();
      return [{
        id: `webcal://${url}/calendar.ics?time_zone=${timeZone}`,
        name: _I18n.default.t("events_calendar.ical")
      }, {
        id: `${url}/calendar.rss?time_zone=${timeZone}`,
        name: _I18n.default.t("events_calendar.rss")
      }];
    },
    actions: {
      onSelect() {}
    }
  }, (_applyDecoratedDescriptor(_obj, "content", [_dec], Object.getOwnPropertyDescriptor(_obj, "content"), _obj)), _obj)));
});