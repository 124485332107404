define("discourse/plugins/discourse-events/discourse/components/events-date-picker", ["exports", "discourse/components/date-picker", "discourse-common/utils/decorators", "discourse/lib/load-script", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "@ember/runloop", "I18n", "discourse-common/lib/object"], function (_exports, _datePicker, _decorators, _loadScript, _dateUtilities, _runloop, _I18n, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _datePicker.default.extend((_dec = (0, _decorators.observes)("value"), _dec2 = (0, _decorators.on)("didInsertElement"), (_obj = {
    layoutName: "components/date-picker",
    setDate() {
      if (this._picker && this.value) {
        this._picker.setDate(this.value);
      }
    },
    _loadDatePicker() {
      const input = this.element.querySelector(".date-picker");
      const container = document.getElementById(this.get("containerId"));
      (0, _loadScript.default)("/javascripts/pikaday.js").then(() => {
        (0, _runloop.next)(() => {
          let default_opts = {
            field: input,
            container: container || this.element,
            bound: container === undefined,
            format: "YYYY-MM-DD",
            firstDay: (0, _dateUtilities.firstDayOfWeek)(),
            i18n: {
              previousMonth: _I18n.default.t("dates.previous_month"),
              nextMonth: _I18n.default.t("dates.next_month"),
              months: moment.months(),
              weekdays: moment.weekdays(),
              weekdaysShort: moment.weekdaysShort()
            },
            onSelect: date => {
              const formattedDate = moment(date).format("YYYY-MM-DD");
              if (this.attrs.onSelect) {
                this.attrs.onSelect(formattedDate);
              }
              if (!this.element || this.isDestroying || this.isDestroyed) {
                return;
              }
              this.set("value", formattedDate);
            }
          };
          this._picker = new Pikaday((0, _object.deepMerge)(default_opts, this._opts())); // eslint-disable-line no-undef
        });
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "setDate", [_dec], Object.getOwnPropertyDescriptor(_obj, "setDate"), _obj), _applyDecoratedDescriptor(_obj, "_loadDatePicker", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_loadDatePicker"), _obj)), _obj)));
});